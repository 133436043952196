/** {*/
/*  box-sizing: border-box;*/
/*}*/

/*body,*/
/*html {*/
/*  background-color: #f6f9fc;*/
/*  font-size: 18px;*/
/*  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;*/
/*  margin: 0;*/
/*}*/

.DemoPickerWrapper {
  padding: 0 12px;
  font-family: "Source Code Pro", monospace;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: white;
  margin: 24px 0 48px;
  width: 100%;
}

.DemoPicker {
  font-size: 18px;
  border-radius: 3px;
  background-color: white;
  height: 48px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  border: 0;
  width: 100%;
  color: #6772e5;
  outline: none;
  background: transparent;

  -webkit-appearance: none;
}

.DemoWrapper {
  margin: 0 auto;
  max-width: 500px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/*.Demo {*/
/*  flex: 1;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: center;*/
/*  padding-bottom: 40%;*/
/*}*/

/*label {*/
/*  color: #6b7c93;*/
/*  font-weight: 300;*/
/*  letter-spacing: 0.025em;*/
/*}*/

/*input,*/
.StripeElement {
  display: block;
  /*margin: 10px 0 20px 0;*/
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  /*box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;*/
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

/*input::placeholder {*/
/*  color: #aab7c4;*/
/*}*/

/*input:focus,*/
.StripeElement--focus {
  /*box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;*/
  box-shadow: 0 0 0 2px #e0e0e0, 0 4px 8px 0 rgb(0 0 0 / 7%), 0 2px 3px 0 rgb(0 0 0 / 5%);
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}
